<template>
  <div :style="bgStyle">
    <div v-if="isDim" class="dim"></div>
    <div class="pc content" :style="contentStylePc">
      <div class="h2">{{ title }}</div>
      <div class="size-18" style="margin-top:12px" v-if="subTitle!==''">{{ subTitle }}</div>
    </div>
    <div class="mobile content" :style="contentStyleM">
      <div class="h6">{{ title }}</div>
      <div class="size-14" style="margin-top:4px" v-if="subTitle!==''">{{ subTitle }}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "LpBanner",
    props: {
      title: {
        type: String,
        default: ''
      },
      subTitle: {
        type: String,
        default: ''
      },
      img: {
        type: String,
        default: ''
      },
      device: {
        type: String,
        default: 'pc'
      },
      color: {
        type: String,
        default: 'white'
      },
      textAlign: {
        type: String,
        default: 'center'
      },
      isDim: {
        type: Boolean,
        default: true
      },
      contentWidthPc: {
        type: String,
        default: 'calc(100% - 80px)'
      },
      contentWidthM: {
        type: String,
        default: 'calc(100% - 80px)'
      },
      height: {
        type: Number
      },
      top: {
        type: String,
        default: '50%'
      }

    },
    created() {
      this.setBg();
      this.ev = () => {
        this.setBg();
      };
      window.addEventListener('resize', this.ev);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.ev);
    },
    data() {
      return {
        bgStyle: {
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          width: '100%',
          color: 'white',
          textAlign: 'center',
          height: '100px',
          position: 'relative'
        },
        ev: undefined
      }
    },
    methods: {
      setBg() {
        this.bgStyle.backgroundImage = `url(/static/img/${this.img})`;
        let height = 0;
        if (this.height) {
          height = this.height;
        } else {
          if(this.device === 'pc') {
            height = window.innerWidth / 6;
          } else {
            height = window.innerWidth / 2.3;
          }
        }
        this.bgStyle.height = `${height}px`;
        this.bgStyle.color = this.color;
        this.bgStyle.textAlign = this.textAlign;
      }
    },
    computed: {
      contentStylePc() {
        return {
          width: this.contentWidthPc,
          top: this.top
        }
      },
      contentStyleM() {
        return {
          width: this.contentWidthM,
          top: this.top
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .dim
    background-color rgba(0,0,0,0.3)
    position absolute
    top 0
    left 0
    width 100%
    height 100%

  .content
    position absolute
    left 50%
    transform translate(-50%, -50%)
    white-space pre-line
    word-break keep-all

</style>
