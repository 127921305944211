<template>
  <div class="container-top">
    <div class="pc">
      <lp-banner img="page_thumb_server_hosting.png" device="pc"
                 title="AWS 서버 호스팅" sub-title="아마존 웹서비스 서버"></lp-banner>
      <div class="container">
        <div class="padding-top-80">
          <div class="flex-align">
            <div class="subtitle3 main">{{ text.title1 }}</div>
            <div class="body4 error margin-left-8">*필수가입사항</div>
          </div>
          <div class="body2 sub2 margin-top-16 introduction-content">{{ text.content1 }}</div>

          <div class="flex-align" style="margin-top:90px">
            <div class="subtitle3 main">플랜 및 가격</div>
            <div class="body4 error margin-left-8">*vat별도</div>
          </div>
          <div class="body2 sub2 margin-top-16 introduction-content">{{ text.content2 }}</div>

          <div class="margin-top-52">
            <div class="card-wrapper">
              <div class="card-info" v-for="item in list" :key="`hosting-${item.id}`">
                <div class="h7 primary">{{ item.name }}</div>
                <div class="margin-top-4">
                  <div v-if="item.price_type===0">
                    <span class="h5 main">{{ item.price | currencyNum }}</span>
                    <span class="body4 main">원</span>
                    <span class="body4 sub3">/</span>
                    <span class="body4 sub3 margin-left-4">월</span>
                  </div>
                  <div class="h5 main" v-else>{{ item.price_text }}</div>
                </div>
                <div class="lp-divider-gray2 margin-top-24 margin-bottom-36"></div>
                <div class="body2 sub3 padding-bottom-24">
                  <div>{{ item.desc }}</div>
                  <div>{{ item.name==='BASIC'? '최초 개설 시 추천':'' }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile">
      <lp-banner img="page_thumb_server_hosting.png" device="mobile"
                 title="AWS 서버 호스팅" sub-title="아마존 웹서비스 서버"></lp-banner>
      <div class="container">
        <div class="padding-top-32">
          <div class="flex-align">
            <div class="subtitle5 main">{{ text.title1 }}</div>
            <div class="body6 error margin-left-8">*필수가입사항</div>
          </div>
          <div class="body4 sub2 margin-top-16 introduction-content">{{ text.content1 }}</div>

          <div class="flex-align margin-top-32">
            <div class="subtitle5 main">{{ text.title2 }}</div>
            <div class="body6 error margin-left-8">*vat별도</div>
          </div>
          <div class="body4 sub2 margin-top-16 introduction-content">{{ text.content2 }}</div>

          <div class="margin-top-52">
            <div>
              <div class="card-info" v-for="item in list" :key="`hosting-${item.id}`">
                <div class="h7 primary">{{ item.name }}</div>
                <div class="margin-top-4">
                  <div v-if="item.price_type===0">
                    <span class="h7 main">{{ item.price | currencyNum }}</span>
                    <span class="body4 main">원</span>
                    <span class="body4 sub3">/</span>
                    <span class="body4 sub3 margin-left-4">월</span>
                  </div>
                  <div class="h7 main" v-else>{{ item.price_text }}</div>
                </div>
                <div class="lp-divider-gray2 margin-top-16 margin-bottom-16"></div>
                <div class="body2 sub3">
                  <div>{{ item.desc }}</div>
                  <div>{{ item.name==='BASIC'? '최초 개설 시 추천':'' }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import LpBanner from "../component/LpBanner";
  export default {
    name: "HostingIntro",
    components: {LpBanner},
    created() {
      this.getData();
    },
    data() {
      return {
        list: [],
        text: {
          title1: 'AWS 서버 호스팅이란?',
          title2: '플랜 및 가격',
          content1: '고객의 서비스 개설 및 플랫폼 유지를 위해 필요한 안정적인 서버를 제공합니다.  런치팩에서 제공하는 호스팅은  AWS 서비스를 기반으로 하며 지속성과 안정성을 보장합니다. \n' +
            '*AWS-Amazon web  services',
          content2: '동시접속자의 수에 따라 플랜이 결정됩니다. 동시 접속 수는 1초에 서비스에서 발생하는 서버 호출 트래픽 양을 대략적으로 환산한 수치로 실제 접속한 사용자의 수와 차이가 있을 수 있습니다.\n' +
            '\n' +
            '100건 미만/100~500건/500건 이상으로 플랜이 구분되며, 약 500건 이상의 동시 접속 수가 꾸준히 발생하는 서비스의 경우 해당 서비스의 트래픽 환경에 적합한 서버를 구성해 드리는 독립 호스팅을 추천드립니다. \n' +
            '\n' +
            '최초 개설시에는 BASIC 단계를 추전드리며, 차후 접속자 수에 따라 플랜변경이 가능합니다. '
        }
      }
    },
    methods: {
      getData() {
        this.$axios.get('public/launchpack/hosting').then(res=>{
          this.list = res.data;
        })
      }
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .pc
    .card-wrapper
      display grid
      grid-gap 24px
      grid-template-columns repeat(3, 1fr)
    .card-info
      border 1px solid $gray1
      padding 32px 40px
      border-radius 8px

  .mobile
    .card-info
      border 1px solid $gray1
      padding 24px
      border-radius 8px
      margin-top 24px

  .introduction-content
    white-space pre-line
</style>
